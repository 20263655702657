import {inject, Injectable} from '@angular/core';
import {DialogService} from '@icz/angular-modal';
import {
  AbstractFileSubjectRelationDialogsManagerService
} from '../../../../../../shared/src/lib/services/abstract-file-subject-relation-dialogs-manager.service';
import {Observable} from 'rxjs';

@Injectable()
export class FileSubjectRelationDialogsManagerService implements AbstractFileSubjectRelationDialogsManagerService{

  private dialogService = inject(DialogService);

  openAddRelationToFileDialog(): Observable<Nullable<boolean>> {
    return this.dialogService.openQuestionDialogWithAnswer({
      title: 'Přidat subjekt také ke spisu',
      description: `Pokud ano, bude subjekt přidán jako související ke spisu, do kterého je tento dokument vložen.`,
      question: 'Chcete přidat subjekt i ke spisu, do kterého je dokument vložen?',
      leftButtonTitle: 'Přidat subjekt také ke spisu',
      rightButtonTitle: 'Nepřidávat subjekt ke spisu',
    });
  }

  openRemoveRelationFromFileDialog(): Observable<Nullable<boolean>> {
    return this.dialogService.openQuestionDialogWithAnswer({
      title: 'Odebrat subjekt také ze spisu',
      description: `Odebíraný subjekt není přidán u žádného dalšího dokumentu ve spisu.`,
      question: 'Má se vazba na subjekt odebrat také u spisu, v kterém je dokument vložen?',
      leftButtonTitle: 'Odebrat subjekt také ze spisu',
      rightButtonTitle: 'Ponechat subjekt ve spisu',
    });
  }

  openAddAllRelatedSubjectsToFileDialog(count: number): Observable<Nullable<boolean>> {
    return this.dialogService.openQuestionDialogWithAnswer({
      title: 'Přidat subjekty dokumentu také ke spisu',
      description: ``,
      question: 'Chcete přidat související subjekty vkládaného dokumentu ({{count}}), které ještě nejsou na spisu, také ke spisu?',
      questionContext: {count},
      leftButtonTitle: 'Přidat subjekty také ke spisu',
      rightButtonTitle: 'Nepřidávat subjekty ke spisu',
    });
  }

  openRemoveRelationsFromFileNotOnFileContentDialog(count: number): Observable<Nullable<boolean>> {
    return this.dialogService.openQuestionDialogWithAnswer({
      title: 'Odebrat subjekty vyjímaného dokumentu ze spisu',
      description: ``,
      question: 'Některé subjekty u vyjímaného dokumentu jsou přidány také ke spisu ({{count}}). Mají se tyto subjekty u spisu ponechat nebo společně s vyjmutím dokumentu ze spisu odebrat? Tyto subjekty nejsou připojeny u žádného dalšího dokumentu ve spisu.',
      questionContext: {count},
      leftButtonTitle: 'Odebrat subjekty také ze spisu',
      rightButtonTitle: 'Ponechat subjekty ve spisu',
    });
  }

  openAddRelationToFileConsignmentDialog(): Observable<Nullable<boolean>> {
    return this.dialogService.openQuestionDialogWithAnswer({
      title: 'Přidat adresáty vypravení také ke spisu',
      description: ``,
      question: 'Mají se adresáti vypravení přidat také ke spisu?',
      leftButtonTitle: 'Přidat subjekty také ke spisu',
      rightButtonTitle: 'Nepřidávat subjekt ke spisu',
    });
  }

}
