export {FileDialogsManagerService} from './lib/modules/document-shared/services/file-dialogs-manager.service';
export {DocumentDialogsManagerService} from './lib/modules/document-shared/services/document-dialogs-manager.service';
export {StorageUnitDialogsManagerService} from './lib/modules/document-shared/services/storage-unit-dialogs-manager.service';
export {FileSubjectRelationDialogsManagerService} from './lib/modules/document-shared/services/file-subject-relation-dialogs-manager.service';
export {InProgressDocumentsModule} from './lib/modules/in-progress-documents/in-progress-documents.module';
export {ExpiringDocumentsModule} from './lib/modules/expiring-documents/expiring-documents.module';
export {SettledDocumentsModule} from './lib/modules/settled-documents/settled-documents.module';
export {DocumentTasksModule} from './lib/modules/document-tasks/document-tasks.module';
export {EsslComponentTasksModule} from './lib/modules/essl-component-tasks/essl-component-tasks.module';
export {OfficerTransfersModule} from './lib/modules/officer-transfers/officer-transfers.module';
export {DocumentSharedModule} from './lib/modules/document-shared/document-shared.module';
import {DocumentsModule} from './lib/documents.module';

export default DocumentsModule;
