import {
  DocumentFileDeactivationDialogComponent,
  DocumentFileDeativationDialogData
} from '../components/document-file-deactivation-dialog/document-file-deactivation-dialog.component';
import {Observable} from 'rxjs';
import {
  AbstractDocumentDialogsManagerService,
  ElasticRegistryOfficeTransferActivityDto,
  ExtendedRegistryOfficeTransferActivityDto,
  isDocumentEntity,
  isStorageUnitObject,
  RegistryOfficeDocumentTransferActivityDto,
  RegistryOfficeFileTransferActivityDto,
  RegistryOfficeStorageUnitTransferActivityDto,
  StorageUnitFillerDialogData
} from '|shared';
import {DisposedEntityDto, DocumentDto, FileDto, StorageUnitDto} from '|api/document';
import {inject, Injectable} from '@angular/core';
import {
  StorageUnitInsertDialogComponent,
  StorageUnitInsertDialogData
} from '../components/storage-unit-insert-dialog/storage-unit-insert-dialog.component';
import {
  RegistryOfficeTransferrableObject,
  TransferToRegistryOfficeDialogComponent
} from '../components/transfer-to-registry-office-dialog/transfer-to-registry-office-dialog.component';
import {
  TransferActivityTakeoverDialogComponent
} from '../components/transfer-activity-takeover-dialog/transfer-activity-takeover-dialog.component';
import {
  SuspendOrResumeSettlementDialogComponent,
  SuspendOrResumeSettlementDialogData
} from '../components/suspend-or-resume-settlement-dialog/suspend-or-resume-settlement-dialog.component';
import {IczModalService, IczModalSizeClass} from '@icz/angular-modal';
import {
  EditRetentionYearDialogComponent,
  EditRetentionYearDialogData
} from '../components/edit-retention-year-dialog/edit-retention-year-dialog.component';
import {
  DocumentFileDisposeDialogComponent,
  DocumentFileDisposeDialogData
} from '../components/document-file-dispose-dialog/document-file-dispose-dialog.component';
import {
  StorageUnitFillerDialogComponent,
} from '../components/storage-unit-filler-dialog/storage-unit-filler-dialog.component';

@Injectable()
export class DocumentDialogsManagerService implements AbstractDocumentDialogsManagerService {

  private modalService = inject(IczModalService);

  openDeactivationDialog(documentData: DocumentDto): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, DocumentFileDeativationDialogData>({
      component: DocumentFileDeactivationDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: 'Zrušení dokumentu',
      },
      data: {
        isDocument: true,
        subject: documentData.subject,
        entityId: documentData.id,
      },
    });
  }

  openDisposeObjectDialog(objectData: DocumentDto | FileDto): Observable<Nullable<DisposedEntityDto>> {
    return this.modalService.openComponentInModal<Nullable<DisposedEntityDto>, DocumentFileDisposeDialogData>({
      component: DocumentFileDisposeDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: isDocumentEntity(objectData.entityType) ? 'Zničení dokumentu' : 'Zničení spisu',
      },
      data: {
        isDocument: isDocumentEntity(objectData.entityType),
        subject: objectData.subject,
        entityId: objectData.id,
      },
    });
  }

  openStorageUnitInsertDialog(selectedObjects: (DocumentDto | FileDto)[], moveFromStorageUnitId?: number): Observable<boolean> {
    const isMove = Boolean(moveFromStorageUnitId);

    return this.modalService.openComponentInModal<boolean, StorageUnitInsertDialogData>({
      component: StorageUnitInsertDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: isMove ? 'Přesunutí do jiné ukládací jednotky' : 'Vložení do ukládací jednotky',
        disableAutoMargin: true,
      },
      data: {
        storageUnitId: moveFromStorageUnitId,
        selectedObjects,
        isMove,
      }
    });
  }

  openCurrentStorageUnitInsertDialog(selectedStorageUnit: StorageUnitDto): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, StorageUnitInsertDialogData>({
      component: StorageUnitInsertDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_RESPONSIVE,
        titleTemplate: 'Vložení do {{storageUnitNumber}} - {{storageUnitName}}',
        titleTemplateContext: {
          storageUnitNumber: selectedStorageUnit.storageUnitNumber,
          storageUnitName: selectedStorageUnit.name,
        },
        disableAutoMargin: true,
      },
      data: {
        storageUnitId: selectedStorageUnit.id,
        isMove: false,
      }
    });
  }

  openTransferToRegistryOfficeDialog(selectedObjects: RegistryOfficeTransferrableObject[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, RegistryOfficeTransferrableObject[]>({
      component: TransferToRegistryOfficeDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné předání do spisovny ({{count}})' : 'Předání {{refNumber}} do spisovny',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: isStorageUnitObject(selectedObjects[0]) ? (selectedObjects[0] as StorageUnitDto).storageUnitNumber! : (selectedObjects[0] as DocumentDto).refNumber!,
        }
      },
      data: selectedObjects
    });
  }

  openTransferTakeoverByRegistryOfficeDialog(selectedActivities: ExtendedRegistryOfficeTransferActivityDto[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, any>({
      component: TransferActivityTakeoverDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: selectedActivities.length > 1 ? 'Hromadné převzetí do spisovny ({{count}})' : 'Převzetí {{entityName}} do spisovny',
        titleTemplateContext: {
          count: String(selectedActivities.length),
          entityName:
            (selectedActivities[0] as ElasticRegistryOfficeTransferActivityDto).entityName ??
            (selectedActivities[0] as RegistryOfficeDocumentTransferActivityDto | RegistryOfficeFileTransferActivityDto).subject ??
            (selectedActivities[0] as RegistryOfficeStorageUnitTransferActivityDto).name,
        }
      },
      data: selectedActivities
    });
  }

  openSuspendSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SuspendOrResumeSettlementDialogData>({
      component: SuspendOrResumeSettlementDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné přerušení řízení ({{count}})' : 'Přerušení řízení {{refNumber}}',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        isSuspend: true,
        selectedObjects
      }
    });
  }

  openResumeSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SuspendOrResumeSettlementDialogData>({
      component: SuspendOrResumeSettlementDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_800_600,
        titleTemplate: selectedObjects.length > 1 ? 'Hromadné obnovení řízení ({{count}})' : 'Obnovení řízení {{refNumber}}',
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        isSuspend: false,
        selectedObjects
      }
    });
  }

  openRetentionYearEditDialog(selectedObjects: (DocumentDto | FileDto)[], editCheckYear: boolean): Observable<boolean> {
    let titleTemplate = '';
    if (selectedObjects.length > 1) {
      if (editCheckYear) {
        titleTemplate = 'Hromadná editace lhůty kontroly událostí ({{count}})';
      } else {
        titleTemplate = 'Hromadná editace roku spouštěcí událostí ({{count}})';
      }
    } else {
      if (editCheckYear) {
        titleTemplate = 'Editace lhůty kontroly událostí {{refNumber}}';
      } else {
        titleTemplate = 'Editace roku spouštěcí událostí {{refNumber}}';
      }
    }

    return this.modalService.openComponentInModal<boolean, EditRetentionYearDialogData>({
      component: EditRetentionYearDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_600_600,
        titleTemplate,
        titleTemplateContext: {
          count: String(selectedObjects.length),
          refNumber: selectedObjects[0].refNumber!,
        }
      },
      data: {
        editCheckYear,
        selectedObjects
      }
    });
  }

  openStorageUnitFillerDialog(config: StorageUnitFillerDialogData): Observable<void> {
    return this.modalService.openComponentInModal<void, StorageUnitFillerDialogData>({
      component: StorageUnitFillerDialogComponent,
      modalOptions: {
        sizeClass: IczModalSizeClass.WH_RESPONSIVE,
        titleTemplate: '', //custom header
        disableAutoMargin: true,
        useCustomHeader: true,
      },
      data: {storageUnitFiller: config.storageUnitFiller, tab: config.tab, isUnitView: config.isUnitView},
    });
  }
}
