import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GeneralScreenAreaModule, NotificationsModule, SharedModule} from '|shared';
import {DocumentsRoutingModule} from './documents-routing.module';
import EsslComponentsOverviewModule from './modules/essl-components-overview/essl-components-overview.module';

@NgModule({
  imports: [CommonModule, GeneralScreenAreaModule, NotificationsModule, DocumentsRoutingModule, SharedModule, EsslComponentsOverviewModule],
})
export class DocumentsModule {}
