import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {GLOBAL_SEARCH_PAGE_NAME} from './modules/global-search/global-search.component';
import {
  ActionRoute,
  DocumentsDashboardRoute,
  DocumentsRoute,
  DynamicRoutePlaceholder,
  guardActiveEsslApplicationModules,
  guardPaperOrPersonalOrInternalConsignment,
  IczRoutes,
  LazyModuleOutletComponent,
  NotFoundPageComponent,
} from '|shared';
import {EsslApplicationModule} from '|api/commons';

const routes: IczRoutes = [
  {
    path: '',
    title: 'Dokumenty',
    component: LazyModuleOutletComponent,
    children: [
      {
        path: `${DocumentsRoute.IN_PROGRESS}`,
        title: 'Vyřizované',
        loadChildren: async () => (await import('./modules/in-progress-documents/in-progress-documents.module')).InProgressDocumentsModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.EXPIRING,
        title: 'Blízký termín',
        loadChildren: async () => (await import('./modules/expiring-documents/expiring-documents.module')).ExpiringDocumentsModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsDashboardRoute.DASHBOARD,
        title: 'Nástěnka',
        loadChildren: () => import('./modules/documents-dashboard/documents-dashboard.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.NOTIFICATIONS,
        title: 'Upozornění',
        loadChildren: () => import('./modules/notifications-overview/notifications-overview.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.ADVANCED_SEARCH,
        title: 'Pokročilé vyhledávání',
        loadChildren: () =>
          import('./modules/global-search-criteria/global-search-criteria.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.SEARCH,
        title: GLOBAL_SEARCH_PAGE_NAME,
        loadChildren: () => import('./modules/global-search/global-search.module'),
        data: {isRootLevelRoute: true, usesAlias: true},
      },
      {
        path: DocumentsRoute.SETTLED,
        title: 'Ukončené',
        loadChildren: async () => (await import('./modules/settled-documents/settled-documents.module')).SettledDocumentsModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.TASKS,
        title: 'Úkolovník',
        loadChildren: async () => (await import('./modules/document-tasks/document-tasks.module')).DocumentTasksModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.SIGNATURE_BOOK,
        title: 'Podpisová kniha',
        loadChildren: async () => (await import('./modules/essl-component-tasks/essl-component-tasks.module')).EsslComponentTasksModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.REGISTRY_OFFICE_TRANSFERS,
        title: 'Spisovna',
        loadChildren: async () => (await import('./modules/officer-transfers/officer-transfers.module')).OfficerTransfersModule,
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: `${DocumentsRoute.DOCUMENT}/${DynamicRoutePlaceholder.UUID}`,
        title: 'Detail dokumentu',
        loadChildren: () => import('./modules/document-detail/document-detail.module'),
      },
      {
        path: `${DocumentsRoute.MANUALLY_SHARED}`,
        title: 'Nasdíleno',
        loadChildren: () => import('./modules/manual-shared-documents/manual-shared-documents.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: `${DocumentsRoute.FILE}/${DynamicRoutePlaceholder.UUID}`,
        title: 'Detail spisu',
        loadChildren: () => import('./modules/file-detail/file-detail.module'),
      },
      {
        path: `${DocumentsRoute.STORAGE_UNIT}/${DynamicRoutePlaceholder.UUID}`,
        title: 'Detail ukládací jednotky',
        loadChildren: () => import('./modules/storage-unit-detail/storage-unit-detail.module'),
      },
      {
        path: `${DynamicRoutePlaceholder.UUID}/${DocumentsRoute.FILE}`,
        title: 'Založení spisu',
        loadChildren: () => import('./modules/new-file/new-file.module'),
      },
      {
        path: `${DocumentsRoute.EMPTY_FILE}`,
        title: 'Založení prázdného spisu',
        loadChildren: () => import('./modules/new-file/new-file.module'),
      },
      {
        path: `${DynamicRoutePlaceholder.UUID}/${DocumentsRoute.DUPLICATE}`,
        title: 'Založení duplikátu dokumentu',
        loadChildren: () => import('./modules/document-duplicate/document-duplicate.module'),
      },
      {
        path: `${DynamicRoutePlaceholder.UUID}/${DocumentsRoute.REGISTER_CONTENT_OFFICER}`,
        title: 'Zaevidování obsahu',
        loadChildren: () => import('./modules/document-register-content/components/document-register-content-by-officer/document-register-content-by-officer.module'),
      },
      {
        path: `${DynamicRoutePlaceholder.UUID}/${DocumentsRoute.REGISTER_CONTENT_FILING_OFFICE}`,
        title: 'Zaevidování obsahu',
        canActivateChild: [guardPaperOrPersonalOrInternalConsignment()],
        loadChildren: () => import('./modules/document-register-content/components/document-register-content-by-filing-office/document-register-content-by-filing-office.module'),
      },
      {
        path: `${DynamicRoutePlaceholder.UUID}/${DocumentsRoute.NEW_RESPONSE_DOCUMENT}`,
        title: 'Založení odpovědi (vyřizující dokument)',
        loadChildren: () => import('./modules/response-document/response-document.module'),
      },
      {
        path: `${DocumentsRoute.CASE_FILES}`,
        title: 'Typové spisy',
        loadChildren: () => import('./modules/case-files/case-files.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
        canActivate: [guardActiveEsslApplicationModules([EsslApplicationModule.CASE_FILE])]
      },
      {
        path: `${DocumentsRoute.CASE_FILE}/${ActionRoute.NEW}`,
        title: 'Založení typového spisu',
        loadChildren: () => import('./modules/case-file/case-file.module'),
      },
      {
        path: `${DocumentsRoute.CASE_FILE}/${DynamicRoutePlaceholder.UUID}`,
        title: 'Detail typového spisu',
        loadChildren: () => import('./modules/case-file-detail/case-file-detail.module'),
      },
      {
        path: `${DocumentsRoute.FILE}/${DynamicRoutePlaceholder.ID}/${DocumentsRoute.OWN_DOCUMENT_TO_FILE}`,
        title: 'Založení vlastního dokumentu do spisu',
        loadChildren: () => import('./modules/own-document/own-document.module'),
      },
      {
        path: `${DocumentsRoute.FILE}/${DynamicRoutePlaceholder.ID}/${DocumentsRoute.RECEIVED_DOCUMENT_TO_FILE}`,
        title: 'Založení doručeného dokumentu do spisu',
        loadChildren: () => import('./modules/create-received-document-by-referent/create-received-document-by-referent.module'),
      },
      {
        path: ActionRoute.NEW,
        title: 'Založení vlastního dokumentu',
        loadChildren: () => import('./modules/own-document/own-document.module'),
        data: {canBeFavourite: true},
      },
      {
        path: DocumentsRoute.RECEIVED,
        title: 'Založení doručeného dokumentu',
        loadChildren: () => import('./modules/create-received-document-by-referent/create-received-document-by-referent.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {
        path: DocumentsRoute.EXTERNAL_APPLICATION_DOCUMENTS,
        title: 'Externí aplikace',
        loadChildren: () => import('./modules/documents-external-application/documents-external-application.module'),
        data: {canBeFavourite: true, isRootLevelRoute: true},
      },
      {path: '**', component: NotFoundPageComponent, data: {skipHistoryBit: true}},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule {}
